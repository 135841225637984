/* FONT COLOR - MAIN COLOR */
/*======================================*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
#page-title {
  color: #4d627b;
}
/* FONT COLOR - BODY COLOR */
/*======================================*/
body,
#content-container {
  color: #7a878e;
}
/* BACKGROUND COLOR - STATE ACTIVE  */
/*======================================*/
.searchbox .custom-search-form .input-group-btn:before,
.pace .pace-activity {
  background-color: #1976d2;
}
/* BACKGROUND COLOR - BODY BACKGROUND */
/*======================================*/
body,
#content-container,
#navbar {
  background-color: #ecf0f5;
}
/* TEXT SELECTION */
/*======================================*/
::selection {
  background-color: #1976d2;
  color: #fff;
}
::-moz-selection {
  background-color: #1976d2;
  color: #fff;
}
/* INPUT PLACEHOLDER */
/*======================================*/
::placeholder {
  color: #cdd2d4 !important;
  opacity: 1;
}
::-webkit-input-placeholder {
  color: #cdd2d4 !important;
}
:-ms-input-placeholder {
  color: #cdd2d4 !important;
}
::-ms-input-placeholder {
  color: #cdd2d4 !important;
}
/* CONTAINER */
/*======================================*/
#container.boxed-layout {
  background-color: #dfe5ee;
}
/* CLEAN ZONE (LOGIN, REGISTER, AND ERROR PAGES CONTAINER) */
/*======================================*/
.cls-container {
  background-color: #ecf0f5;
}
.cls-container,
.cls-container a:not(.btn),
.cls-container a:hover:not(.btn),
.cls-container a:focus:not(.btn) {
  color: #69757b;
}
#container.cls-container .cls-brand .brand-icon,
#container.cls-container .cls-brand .brand-title {
  color: #4d627b;
}
/* BOXED LAYOUT */
/*======================================*/
@media (min-width: 1024px) {
  #container.boxed-layout,
  #container.boxed-layout.navbar-fixed #navbar {
    background-color: #8c979b;
  }
}
/* SCROLLBAR */
/*======================================*/
.nano > .nano-pane > .nano-slider,
.pace .pace-progress {
  background-color: #1669bb;
}
.pace .pace-progress-inner {
  box-shadow: 0 0 10px #1976d2, 0 0 5px #1976d2;
}
/* NAVBAR
    /*====================================*/
@media (min-width: 768px) {
  #navbar {
    background-color: transparent;
  }
}
.navbar-content {
  background-color: #1976d2;
}
.navbar-aside-icon > i:before {
  background-color: #fff !important;
  box-shadow: 0 0.45em 0 #fff, 0 0.9em 0 #fff !important;
}
/* NAVBAR ICON & BUTTON */
/*======================================*/
.navbar-icon-color,
.navbar-top-links > li > a:focus:not(.mainnav-toggle) > i,
.navbar-top-links > li > a:hover:not(.mainnav-toggle) > i {
  color: #d9d9d9;
}
.navbar-top-links > li > a {
  color: #fff !important;
}
.navbar-top-links > li > a:not(.mainnav-toggle) > i {
  color: #fff;
}
.navbar-top-links > li > a:hover {
  background-color: #1976d2 !important;
  color: #d9d9d9;
}
.navbar-top-links > .open > a,
.navbar-top-links > .open > a:focus {
  background-color: #1870c7 !important;
  color: #d9d9d9;
}
.navbar-top-links .tgl-menu-btn > a,
.navbar-top-links .tgl-menu-btn > a:hover,
.navbar-top-links .tgl-menu-btn > a:focus {
  color: #fff;
}
.navbar-top-links .head-list li a i {
  color: #4d627b;
}
.navbar-top-links .head-list li a:hover {
  color: #7a878e;
}
/* NAVBAR RESPONSIVE */
/*======================================*/
@media (min-width: 768px) {
  .navbar-header:before {
    background-color: #1976d2;
  }
  #navbar-container {
    background-color: #1976d2;
  }
  #container.mainnav-in .brand-title,
  #container.mainnav-sm .brand-title,
  #container.mainnav-lg .brand-title {
    color: #fff;
  }
}
/* SEARCHBOX */
/*======================================*/
.navbar-content .custom-search-form i {
  color: #fff;
}
@media (min-width: 768px) {
  .navbar-content .custom-search-form input {
    color: #fff;
  }
  .navbar-content .custom-search-form input::placeholder {
    color: rgba(255, 255, 255, 0.4) !important;
    opacity: 1;
  }
  .navbar-content .custom-search-form input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.4) !important;
  }
  .navbar-content .custom-search-form input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.4) !important;
  }
  .navbar-content .custom-search-form input::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.4) !important;
  }
}
/* NAVIGATION */
/*======================================*/
#mainnav {
  background-color: #fff;
  color: #6b737c;
}
#mainnav .list-header {
  color: #3c4045;
}
/* PROFILE WIDGET */
/*======================================*/
.mainnav-profile,
.mainnav-profile .mnp-name,
.mainnav-profile .dropdown-caret,
.mainnav-profile .mnp-desc {
  color: #4d627b;
}
.mainnav-profile .profile-wrap {
  background-color: #fff;
}
#mainnav .mainnav-profile .list-group {
  background-color: #fafafa;
}
#mainnav .mainnav-profile .list-group-item {
  color: #4d627b;
}
#mainnav .mainnav-profile .list-group-item:hover {
  color: #303d4c;
}
/* NAVIGATION MENU */
/*======================================*/
#mainnav-menu > li > a:hover,
#mainnav-menu > li > a:active {
  color: #1976d2;
}
#mainnav-menu > li > a.hover {
  color: #1976d2;
  background-color: #ffffff;
}
#mainnav-menu > .active-link > a,
#mainnav-menu > .active-link > a:hover {
  color: #fff;
  background-color: #1976d2;
}
#mainnav-menu > .active-link > a i,
#mainnav-menu > .active-link > a:hover i {
  color: #fff;
}
#mainnav-menu > .active-sub > a,
#mainnav-menu > .active-sub > a:hover {
  color: #fff;
  background-color: #1976d2;
}
#mainnav-menu > .active-sub .active-sub > a,
#mainnav-menu > .active-sub .active-sub > a:hover {
  color: #1976d2;
}
#mainnav-menu .active:not(.active-sub) > a {
  color: #1976d2;
}
#mainnav-menu > .active {
  background-color: #fafafa;
}
#mainnav-menu > .active .active {
  background-color: #f5f5f5;
}
#mainnav-menu ul a:hover,
.menu-popover .sub-menu ul a:hover,
#mainnav-menu ul ul a:hover,
.menu-popover .sub-menu ul ul a:hover,
#mainnav-menu ul .active-link a,
.menu-popover .sub-menu ul .active-link a {
  color: #1976d2;
}
#container.mainnav-sm #mainnav-menu > .active-link > a,
#container.mainnav-sm #mainnav-menu > .active-sub > a,
#container.mainnav-sm #mainnav-menu > .active-link a:hover,
#container.mainnav-sm #mainnav-menu > .active-sub a:hover {
  background-color: #1976d2;
  box-shadow: none;
}
/* NAVIGATION - SHORTCUT BUTTONS */
/*======================================*/
#mainnav-shortcut .shortcut-grid {
  color: #6b737c;
}
#mainnav-shortcut .shortcut-wrap .shortcut-grid:hover > .icon-wrap {
  background-color: #1976d2;
  color: #fff;
}
/* NAVIGATION - WIDGET */
/*======================================*/
.mainnav-widget {
  color: #6b737c;
}
/* NAVIGATION - COLLAPSING  */
/*======================================*/
@media (min-width: 768px) {
  #container.mainnav-sm ul:not(.shortcut-wrap) .shortcut-grid:hover,
  #container.mainnav-sm .popover.mainnav-shortcut {
    background-color: #ffffff;
    color: #1976d2;
  }
  #container.mainnav-sm #mainnav-menu .active-link > a,
  #container.mainnav-sm #mainnav-menu .active-sub > a {
    color: #fff;
  }
  #container.mainnav-sm #mainnav {
    position: relative;
  }
  #container.mainnav-sm #mainnav .mainnav-widget > .show-small a {
    color: #6b737c;
  }
  #container.mainnav-sm #mainnav .mainnav-widget > .show-small a:hover {
    color: #1976d2;
  }
  #container.mainnav-sm #mainnav .mainnav-widget .hover {
    color: #1976d2;
    background-color: #ffffff;
  }
  .menu-popover .sub-menu ul {
    background-color: #f7f7f7;
  }
  .menu-popover .sub-menu ul ul {
    background-color: #f2f2f2;
  }
  .menu-popover .sub-menu ul ul > li > a:hover {
    color: #1976d2;
  }
  .menu-popover .sub-menu ul a:hover {
    color: #1976d2;
  }
  .menu-popover .sub-menu ul li > a {
    color: #6b737c;
  }
  .menu-popover .single-content {
    color: #1976d2;
    background-color: #ffffff;
  }
  .menu-popover.popover {
    background-color: #fafafa;
    color: #1976d2;
  }
  .menu-popover > .popover-title {
    background-color: #ffffff;
    color: #1976d2;
  }
  .menu-popover .mainnav-widget-content {
    background-color: #ffffff;
  }
}
.mainnav-sm .popover.mainnav-shortcut {
  background-color: #fafafa;
  color: #1976d2;
}
/* NAVIGATION - OFFCANVAS */
/*======================================*/
@media (min-width: 768px) {
  #container.mainnav-in.easeInBack:not(.boxed-layout) #mainnav-container {
    box-shadow: -20px 0 0 0 #fff;
  }
  #container.mainnav-out .brand-title {
    color: #fff;
  }
}
/* ASIDE */
/* ===================================== */
#container #aside .list-link li a:not(.btn) {
  color: #d1d1d1;
}
#container #aside .list-link li a:not(.btn):hover {
  color: #fff;
}
#container #aside .badge-stat {
  color: #344146;
}
#container #aside .text-main {
  color: #fff;
}
#aside {
  background-color: #344146;
  color: #fff;
}
#aside .btn-link {
  color: #fff;
}
#aside .text-muted {
  color: #879da6;
}
/* ASIDE WITH TABS */
/* ===================================== */
#aside-container #aside .nav-tabs li {
  border-bottom: 1px solid #293337;
}
#aside-container #aside .nav-tabs li > a {
  background-color: #344146;
}
#aside-container #aside .nav-tabs li > a:before {
  background: #1976d2;
}
#aside-container #aside .nav-tabs .active a:before {
  background: #1976d2;
}
#container.aside-bright #aside .nav-tabs li {
  border-bottom: 1px solid #f9f9f9;
}
#container.aside-bright #aside .nav-tabs li > a {
  background-color: #fff;
}
/* ASIDE : BRIGHT COLOR THEMES */
/* ===================================== */
#container.aside-bright #aside {
  background-color: #fff;
  color: #7a878e;
}
#container.aside-bright #aside .badge-stat {
  color: #fff;
}
#container.aside-bright #aside .text-main {
  color: #4d627b;
}
#container.aside-bright #aside .text-light {
  color: #7a878e;
}
#container.aside-bright #aside .text-muted {
  color: #b1b9bd;
}
#container.aside-bright #aside .progress {
  background-color: #e6e6e6;
}
#container.aside-bright #aside .list-link li a:not(.btn) {
  color: #88939a;
}
#container.aside-bright #aside .list-link li a:not(.btn):hover {
  color: #7a878e;
}
#container.aside-bright #aside a:not(.btn) {
  color: #7a878e;
}
#container.aside-bright #aside a:not(.btn):hover,
#container.aside-bright #aside a:not(.btn):focus {
  color: #626d73;
}
#container.aside-bright #aside .btn-link {
  color: #7a878e;
}
/* FOOTER */
/*======================================*/
#footer {
  background-color: #f6f8fa;
  color: #7a878e;
}
/* BOOTSTRAP'S COMPONENTS */
/*======================================*/
.jumbotron {
  background-color: #dbe3ec;
}
/* MEGAMENU */
/*======================================*/
.open.mega-dropdown > .mega-dropdown-toggle:before {
  border-bottom: 7px solid rgba(0, 0, 0, 0.07);
}
.open.mega-dropdown > .mega-dropdown-toggle:after {
  border-bottom: 7px solid #fff;
}
/* TIMELINE */
/*======================================*/
.timeline {
  background-color: #ecf0f5;
  color: #7a878e;
}
.timeline:before,
.timeline:after {
  background-color: #bfc5c9;
}
.timeline:after {
  border: 1px solid #bfc5c9;
  background-color: #ecf0f5;
}
.timeline-time {
  background-color: #ecf0f5;
  color: #7a878e;
}
.timeline-icon:not([class*="bg-"]) {
  background-color: #ecf0f5;
}
.timeline-icon:empty {
  border: 2px solid #bfc5c9;
}
.timeline-label {
  background-color: #ffffff;
}
.timeline-label:after {
  border-right: 9px solid #ffffff;
}
.panel .timeline,
.panel .timeline:after,
.panel .timeline-time,
.panel .timeline-label,
.panel .timeline-icon:not([class^="bg-"]):not([class*=" bg-"]) {
  background-color: #fff;
}
.panel .timeline-label {
  background-color: #f3f5f9;
}
.panel .timeline-label:after {
  border-right-color: #f3f5f9;
}
@media (min-width: 768px) {
  .two-column.timeline .timeline-entry:nth-child(even) .timeline-label:after {
    border-left: 9px solid #ffffff;
  }
}
/* TAGS */
/*======================================*/
.tag:not(.label) {
  background-color: #fff;
  border: 1px solid #d4dee0;
}
/* EMAIL */
/*======================================*/
.mail-time {
  color: #b1b9bd;
}
.mail-list > li:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.01);
}
.mail-list > li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.mail-list > li.highlight {
  background-color: #f2f0c2;
}
.mail-list a,
.mail-list a:focus {
  display: inline-block;
  color: #7a878e;
}
.mail-star > a,
.mail-star > a:focus {
  color: #d3d7da;
}
.mail-starred .mail-star > a {
  color: #ffa100;
}
.mail-list .mail-star > a:hover {
  color: #ffbd4d;
}
.mail-attach-list > li .mail-file-img,
.mail-attach-list > li .mail-file-icon {
  background-color: #f8f9fa;
}
/* FORM WIZARD */
/*======================================*/
.wz-icon-bw li.active ~ li > a .icon-wrap {
  color: #7a878e;
}
.wz-icon-bw li:not(.active) > a p {
  color: #7a878e !important;
}
/* NIFTY NOTIFICATIONS */
/*======================================*/
.alert-primary .alert-icon {
  color: #fff;
}
.alert-info .alert-icon {
  color: #fff;
}
.alert-success .alert-icon {
  color: #fff;
}
.alert-warning .alert-icon {
  color: #fff;
}
.alert-danger .alert-icon {
  color: #fff;
}
.alert-mint .alert-icon {
  color: #fff;
}
.alert-purple .alert-icon {
  color: #fff;
}
.alert-pink .alert-icon {
  color: #fff;
}
.alert-dark .alert-icon {
  color: #fff;
}
/* NIFTY PURE CSS SELECT COMPONENT */
/*======================================*/
.select:before {
  border-top-color: #b1b9bd;
}
.select select:focus {
  border-color: #1976d2;
}
.pci-hor-dots:before,
.pci-ver-dots:before {
  background-color: #7a878e;
  box-shadow: 0 0.45em 0 #7a878e, 0 0.9em 0 #7a878e;
}
/* BREADCRUMB */
/*======================================*/
.breadcrumb li,
.breadcrumb li a {
  color: #626d73;
}
/* FORM CONTROL  */
/*======================================*/
.form-control:focus {
  border-color: #1976d2;
}
/* LIST GROUP */
/*======================================*/
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  background-color: #1976d2 !important;
  border-color: #1976d2;
  color: #fff;
}
.list-group-item.active .list-group-item-text,
.list-group-item.active:hover .list-group-item-text,
.list-group-item.active:focus .list-group-item-text {
  color: #fff;
}
/* DROPDOWN */
/*======================================*/
.dropdown-menu:not(.head-list) > li > a:hover {
  background-color: #1976d2;
  color: #fff;
}
/* POPOVER */
/*======================================*/
.popover {
  border: 1px solid #e9edf3;
}
/* WELLS */
/*======================================*/
.well {
  background-color: #e9edf3;
  border-color: #e2e8f0;
}
/* PAGER */
/*======================================*/
.pager li > a:active {
  background-color: #1976d2 !important;
  color: #fff !important;
}
.pager li > a:hover,
.pager li > a:focus {
  background-color: #fff;
  border-color: #1976d2;
  color: #1976d2;
  box-shadow: inset 0 0 1px #1976d2, 0 3px 15px rgba(0, 0, 0, 0.25);
  z-index: 2;
}
/* PAGINATION */
/*======================================*/
.pagination > li a:hover,
.pagination > li a:focus {
  border-color: #1976d2;
  color: #1976d2;
  box-shadow: inset 0 0 1px #1976d2;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #1976d2;
  border-color: #1976d2;
}
/* TABS */
/*======================================*/
.tab-base .tab-content {
  background-color: #fff;
}
.tab-base .tab-content .tab-footer {
  background-color: #f6f8fa;
  color: #7a878e;
  border-color: #eff3f7;
}
.nav-tabs li a {
  color: #7a878e;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #4d627b;
}
/* BUTTONS */
/*======================================*/
.btn-primary,
.btn-primary:focus {
  background-color: #1b7ee0;
  border-color: #1b7ee0 !important;
  color: #fff;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.btn-primary:disabled,
.btn-primary.disabled,
.btn-primary:not([class*="btn-active-"]) + .dropdown-menu > li > a:hover,
.btn-primary:not([class*="btn-active-"]) + .dropdown-menu > li > a:focus,
.btn-primary:not([class*="btn-active-"]) + .dropdown-menu > .active > a,
.btn-primary + .dropdown-menu:not(.head-list) > li > a.active,
.open > .dropdown-toggle.btn-primary,
.btn-hover-primary:hover,
.btn-hover-primary:active,
.btn-hover-primary.active,
.btn-active-primary.btn:active,
.btn-active-primary.btn.active,
.btn-active-primary + .dropdown-menu > li > a:hover,
.btn-active-primary + .dropdown-menu > li > a:focus,
.btn-active-primary + .dropdown-menu > .active > a,
.open .dropdown-toggle.btn.btn-active-primary,
.btn-group.open .dropdown-toggle.btn.btn-active-primary {
  background-color: #1564b2 !important;
  border-color: #1564b2 !important;
  color: #fff !important;
}
/* PANELS */
/*======================================*/
.panel-primary .panel-heading,
.panel-primary .panel-footer,
.panel-primary.panel-colorful {
  background-color: #1976d2;
  border-color: #1976d2;
  color: #fff;
}
.panel-primary.panel-colorful {
  box-shadow: 0 1px 1px #0e4377;
}
.panel-footer {
  background-color: #fdfdfe;
  color: #7a878e;
}
/* MAGIC CHECK */
/*======================================*/
.magic-radio + label:hover:before,
.magic-checkbox + label:hover:before {
  border-color: #1976d2;
}
.magic-checkbox:checked + label:before {
  background: #1976d2;
  border-color: #1976d2;
}
.magic-radio + label:after {
  background: #1976d2;
}
.magic-radio:checked + label:before {
  border-color: #1976d2;
}
.magic-radio:checked:focus + label:before,
.magic-checkbox:checked:focus + label:before {
  box-shadow: 0 0 0 10px rgba(25, 118, 210, 0.17), inset 0 0 0 10px rgba(22, 119, 179, 0.17);
}
/* MIX COMPONENTS */
/*======================================*/
.alert-primary,
.btn-primary {
  border-color: #1976d2;
}
.btn-primary,
.label-primary,
.badge-primary,
.progress-bar-primary,
.alert-primary,
.bg-primary {
  background-color: #1976d2;
  color: #fff;
}
/* SCROLL TO TOP BUTTON */
/*======================================*/
.scroll-top.in {
  background-color: #1976d2;
  color: #fff;
}
body .chosen-container-multi .chosen-choices li.search-choice,
body .chosen-container .chosen-results li.highlighted {
  background-color: #1976d2;
  color: #fff;
}
body .select2-container--default .select2-results__option[aria-selected="true"]:hover,
body .select2-container--default .select2-selection--multiple .select2-selection__choice,
body .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #1976d2;
  color: #fff;
}
body .select2-container--default .select2-selection--multiple .select2-selection__choice {
  border-color: #1976d2;
}
body .datepicker table thead {
  color: #4d627b;
}
body .datepicker-switch {
  color: #1976d2;
}
body .datepicker table tr td span.active.active,
body .datepicker table tr td span.active.disabled.active,
body .datepicker table tr td span.active.disabled:active,
body .datepicker table tr td span.active.disabled.active:hover,
body .datepicker table tr td span.active.disabled:hover:active,
body .datepicker table tr td span.active:active,
body .datepicker table tr td span.active.active:hover,
body .datepicker table tr td span.active:hover:active,
body .datepicker table tr td.active.active,
body .datepicker table tr td.active.highlighted.active,
body .datepicker table tr td.active.highlighted:active,
body .datepicker table tr td.active:active,
body .datepicker table tr td.selected,
body .datepicker table tr td.selected.highlighted {
  background-color: #1976d2;
  border-color: #1976d2;
  color: #fff;
}
body .datepicker table tr td.active.active:hover {
  background-color: #145ca4;
}
body .datepicker table tr td.range {
  background-color: #92c2f2;
}
body .bootstrap-timepicker-widget table td a {
  color: #4d627b;
}
body .noUi-connect {
  background: #1976d2;
}
body .noUi-handle {
  background: #1976d2;
}
body .noUi-marker-large,
body .noUi-marker {
  background: rgba(255, 255, 255, 0.2);
}
body .fixed-table-container tbody .selected td,
body #container .table.dataTable tbody tr.selected {
  background-color: #1976d2;
  color: #fff;
}
body .fixed-table-container tbody .selected td .text-muted,
body #container .table.dataTable tbody tr.selected .text-muted,
body .fixed-table-container tbody .selected td .btn-link,
body #container .table.dataTable tbody tr.selected .btn-link {
  color: #fff;
}
body .jstree-default .jstree-clicked {
  background-color: #1976d2 !important;
  color: #fff;
}
body .dd-dragel {
  color: #4d627b;
}
body .dd-dragel .dd-handle-btn:before {
  background-color: #fff;
  color: #fff;
}
body .dd-dragel .dd-content,
body .dd-dragel > .dd-item .dd-handle {
  background-color: #1976d2;
  color: #fff;
  box-shadow: none;
}
body .dd-outline:hover,
body .dd-dashed:hover {
  border-color: #1976d2;
}
@keyframes dd-update {
  1% {
    color: #fff;
    background-color: #1976d2;
  }
  100% {
    background-color: #fff;
  }
}
@keyframes dd-dragging {
  1% {
    background-color: #fff;
    box-shadow: none;
  }
  100% {
    background-color: #1976d2;
    color: #fff;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
  }
}
body .note-editable.panel-body {
  border: 1px solid rgba(0, 0, 0, 0.07);
}
#content-container:before {
  content: '';
  display: none;
}
#page-head,
#page-content {
  position: static;
}
.page-header {
  color: #4d627b;
}
#page-head,
#page-head h1,
#page-head h2,
#page-head h3,
#page-head h4,
#page-head h5,
#page-head h6,
#page-head .text-main,
#page-head .breadcrumb li,
#page-head .breadcrumb li a,
#page-head .breadcrumb .active {
  color: #4d627b;
}
#page-head .breadcrumb > li + li:before {
  border-color: #4d627b;
}
.select select {
  color: #7a878e;
}
.panel .fixed-fluid [class*="fixed-"] {
  background-color: transparent;
}
.fixed-fluid [class*="fixed-"] {
  background-color: #ecf0f5;
  border-radius: 3px;
}
/* CUSTOM FIXES */
.dropdown-menu>.active>a {
    background-color: #1976d2 !important;
    border-color: #1976d2 !important;
    color: #fff !important;
}

/* DARK MODE */

.night #mainnav {
    background-color: #3d4553;
    color: #abb1b7;
}

.night #content-container, body.night {
    background-color: rgb(52, 59, 71);
    color: rgb(171, 171, 171);
}

.night #footer {
    background-color: rgb(46, 52, 62);
    color: rgb(171, 171, 171);
}

.night .panel {
      background-color: rgb(61, 69, 83);
}

.night .panel-success .panel-heading, .night .panel-success.panel-colorful {
    background-color: #8bc34a;
    border-color: #8bc34a;
    color: #fff;
}

.night .panel.panel-success .panel-footer, .night .panel.panel-danger .panel-footer , .night .panel.panel-warning .panel-footer  {
    background-color: #3f4756;
    color: #7a878e;
    border-color: rgba(0, 0, 0, 0.02);
}

.night #page-head,
.night #page-head .breadcrumb .active,
.night #page-head .breadcrumb li,
.night #page-head .breadcrumb li a,
.night #page-head .text-main,
.night #page-head h1,
.night #page-head h2,
.night #page-head h3,
.night #page-head h4,
.night #page-head h5,
.night #page-head h6 {
    color: #d4d4d4;
}



/* FONT COLOR - MAIN COLOR */
/*======================================*/
.night h1,
.night h2,
.night h3,
.night h4,
.night h5,
.night h6,
.night .h1,
.night .h2,
.night .h3,
.night .h4,
.night .h5,
.night .h6,
.night #page-title {
  color: #d4d4d4;
}

/* BACKGROUND COLOR - BODY BACKGROUND */
/*======================================*/
.night body,
.night #content-container,
.night #navbar {
  background-color: #343b47;
}

/* CONTAINER */
/*======================================*/
.night #container.boxed-layout {
  background-color: #2b313b;
}
/* CLEAN ZONE (LOGIN, REGISTER, AND ERROR PAGES CONTAINER) */
/*======================================*/
.night .cls-container {
  background-color: #343b47;
}
.night .cls-container,
.night .cls-container a:not(.btn),
.night .cls-container a:hover:not(.btn),
.night .cls-container a:focus:not(.btn) {
  color: #999999;
}
.night #container.cls-container .cls-brand .brand-icon,
.night #container.cls-container .cls-brand .brand-title {
  color: #d4d4d4;
}
/* BOXED LAYOUT */
/*======================================*/
@media (min-width: 1024px) {
  .night #container.boxed-layout,
  .night #container.boxed-layout.navbar-fixed #navbar {
    background-color: #454f5f;
  }
}

/* NAVBAR
    /*====================================*/


.navbar-aside-icon > i:before {
  background-color: #fff !important;
  box-shadow: 0 0.45em 0 #fff, 0 0.9em 0 #fff !important;
}
/* NAVBAR ICON & BUTTON */
/*======================================*/

.night .navbar-top-links .head-list li a:hover {
  color: #ababab;
}

.night .navbar-top-links .head-list li a:hover i {
  color: #d4d4d4;
}
/* NAVBAR RESPONSIVE */
/*======================================*/
@media (min-width: 768px) {
  .night #container.mainnav-in .brand-title,
  .night #container.mainnav-sm .brand-title,
  .night #container.mainnav-lg .brand-title {
    color: #fff;
  }
}
/* SEARCHBOX */
/*======================================*/
.night .navbar-content .custom-search-form i {
  color: #fff;
}
@media (min-width: 768px) {
  .night .navbar-content .custom-search-form input {
    color: #fff;
  }
  .night .navbar-content .custom-search-form input::placeholder {
    color: rgba(255, 255, 255, 0.4) !important;
    opacity: 1;
  }
  .night .navbar-content .custom-search-form input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.4) !important;
  }
  .night .navbar-content .custom-search-form input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.4) !important;
  }
  .night .navbar-content .custom-search-form input::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.4) !important;
  }
}
/* NAVIGATION */
/*======================================*/
.night #mainnav {
  background-color: #3d4553;
  color: #abb1b7;
}
.night #mainnav .list-header {
  color: #747e88;
}
/* PROFILE WIDGET */
/*======================================*/
.night .mainnav-profile,
.night .mainnav-profile .mnp-name,
.night .mainnav-profile .dropdown-caret,
.night .mainnav-profile .mnp-desc {
  color: #fff;
}
.night .mainnav-profile .profile-wrap {
  background-color: #3d4553;
}
.night #mainnav .mainnav-profile .list-group {
  background-color: #38404d;
}
.night #mainnav .mainnav-profile .list-group-item {
  color: #fff;
}
.night #mainnav .mainnav-profile .list-group-item:hover {
  color: #d9d9d9;
}
/* NAVIGATION MENU */
/*======================================*/
.night #mainnav-menu > li > a:hover,
.night #mainnav-menu > li > a:active {
  color: #fff;
}
.night #mainnav-menu > li > a.hover {
  color: #fff;
  background-color: #434c5c;
}

.night #mainnav-menu > .active-link > a i,
.night #mainnav-menu > .active-link > a:hover i {
  color: #fff;
}

.night #mainnav-menu > .active-sub .active-sub > a,
.night #mainnav-menu > .active-sub .active-sub > a:hover {
  color: #fff;
}
.night #mainnav-menu .active:not(.active-sub) > a {
  color: #fff;
}
.night #mainnav-menu > .active {
  background-color: #38404d;
}
.night #mainnav-menu > .active .active {
  background-color: #343b47;
}

/* NAVIGATION - SHORTCUT BUTTONS */
/*======================================*/
.night #mainnav-shortcut .shortcut-grid {
  color: #abb1b7;
}
/* NAVIGATION - WIDGET */
/*======================================*/
.night .mainnav-widget {
  color: #abb1b7;
}
/* NAVIGATION - COLLAPSING  */
/*======================================*/
@media (min-width: 768px) {
  .night #container.mainnav-sm ul:not(.shortcut-wrap) .shortcut-grid:hover,
  .night #container.mainnav-sm .popover.mainnav-shortcut {
    background-color: #434c5c;
    color: #fff;
  }


  .night #container.mainnav-sm #mainnav .mainnav-widget > .show-small a {
    color: #abb1b7;
  }

  .night #container.mainnav-sm #mainnav .mainnav-widget .hover {
    color: #fff;
    background-color: #434c5c;
  }
  .night .menu-popover .sub-menu ul {
    background-color: #363d4a;
  }
  .night .menu-popover .sub-menu ul ul {
    background-color: #323944;
  }

  .night .menu-popover .sub-menu ul li > a {
    color: #abb1b7;
  }
  .night .menu-popover .single-content {
    color: #fff;
    background-color: #434c5c;
  }
  .night .menu-popover.popover {
    background-color: #38404d;
    color: #fff;
  }
  .night .menu-popover > .popover-title {
    background-color: #434c5c;
    color: #fff;
  }
  .night .menu-popover .mainnav-widget-content {
    background-color: #434c5c;
  }
}
.night .mainnav-sm .popover.mainnav-shortcut {
  background-color: #38404d;
  color: #fff;
}
/* NAVIGATION - OFFCANVAS */
/*======================================*/
@media (min-width: 768px) {
  .night #container.mainnav-in.easeInBack:not(.boxed-layout) #mainnav-container {
    box-shadow: -20px 0 0 0 #3d4553;
  }
}
/* ASIDE */
/* ===================================== */
.night #container #aside .list-link li a:not(.btn) {
  color: #d1d1d1;
}
.night #container #aside .list-link li a:not(.btn):hover {
  color: #fff;
}
.night #container #aside .badge-stat {
  color: #344146;
}
.night #container #aside .text-main {
  color: #fff;
}
.night #aside {
  background-color: #344146;
  color: #fff;
}
.night #aside .btn-link {
  color: #fff;
}
.night #aside .text-muted {
  color: #879da6;
}
/* ASIDE WITH TABS */
/* ===================================== */
.night #aside-container #aside .nav-tabs li {
  border-bottom: 1px solid #293337;
}
.night #aside-container #aside .nav-tabs li > a {
  background-color: #344146;
}
.night #container.aside-bright #aside .nav-tabs li {
  border-bottom: 1px solid #373f4b;
}
.night #container.aside-bright #aside .nav-tabs li > a {
  background-color: #3d4553;
}
/* ASIDE : BRIGHT COLOR THEMES */
/* ===================================== */
.night #container.aside-bright #aside {
  background-color: #3d4553;
  color: #ababab;
}
.night #container.aside-bright #aside .badge-stat {
  color: #3d4553;
}
.night #container.aside-bright #aside .text-main {
  color: #d4d4d4;
}
.night #container.aside-bright #aside .text-light {
  color: #ababab;
}
.night #container.aside-bright #aside .text-muted {
  color: #858585;
}
.night #container.aside-bright #aside .progress {
  background-color: #272c35;
}
.night #container.aside-bright #aside .list-link li a:not(.btn) {
  color: #b8b8b8;
}
.night #container.aside-bright #aside .list-link li a:not(.btn):hover {
  color: #ababab;
}
.night #container.aside-bright #aside a:not(.btn) {
  color: #ababab;
}
.night #container.aside-bright #aside a:not(.btn):hover,
.night #container.aside-bright #aside a:not(.btn):focus {
  color: #929292;
}
.night #container.aside-bright #aside .btn-link {
  color: #ababab;
}
/* FOOTER */
/*======================================*/
.night #footer {
  background-color: #2e343e;
  color: #ababab;
}
/* BOOTSTRAP'S COMPONENTS */
/*======================================*/
.night .jumbotron {
  background-color: #292f38;
}
/* MEGAMENU */
/*======================================*/
.night .open.mega-dropdown > .mega-dropdown-toggle:before {
  border-bottom: 7px solid rgba(230, 230, 230, 0.03);
}
.night .open.mega-dropdown > .mega-dropdown-toggle:after {
  border-bottom: 7px solid #fff;
}
/* TIMELINE */
/*======================================*/
.night .timeline {
  background-color: #343b47;
  color: #ababab;
}
.night .timeline:before,
.night .timeline:after {
  background-color: rgba(255, 255, 255, 0.1);
}
.night .timeline:after {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #343b47;
}
.night .timeline-time {
  background-color: #343b47;
  color: #ababab;
}
.night .timeline-icon:not([class*="bg-"]) {
  background-color: #343b47;
}
.night .timeline-icon:empty {
  border: 2px solid rgba(255, 255, 255, 0.1);
}
.night .timeline-label {
  background-color: #525d70;
}
.night .timeline-label:after {
  border-right: 9px solid #525d70;
}
.night .panel .timeline,
.night .panel .timeline:after,
.night .panel .timeline-time,
.night .panel .timeline-label,
.night .panel .timeline-icon:not([class^="bg-"]):not([class*=" bg-"]) {
  background-color: #fff;
}
.night .panel .timeline-label {
  background-color: #414a59;
}
.night .panel .timeline-label:after {
  border-right-color: #414a59;
}
@media (min-width: 768px) {
  .night .two-column.timeline .timeline-entry:nth-child(even) .timeline-label:after {
    border-left: 9px solid #525d70;
  }
}
/* TAGS */
/*======================================*/
.night .tag:not(.label) {
  background-color: #fff;
  border: 1px solid #d4dee0;
}
/* EMAIL */
/*======================================*/
.night .mail-time {
  color: #858585;
}
.night .mail-list > li:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.01);
}
.night .mail-list > li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.night .mail-list > li.highlight {
  background-color: #f2f0c2;
}
.night .mail-list a,
.night .mail-list a:focus {
  display: inline-block;
  color: #ababab;
}
.night .mail-star > a,
.night .mail-star > a:focus {
  color: #d3d7da;
}
.mail-starred .mail-star > a {
  color: #ffa100;
}
.mail-list .mail-star > a:hover {
  color: #ffbd4d;
}
.night .mail-attach-list > li .mail-file-img,
.night .mail-attach-list > li .mail-file-icon {
  background-color: #f8f9fa;
}
/* FORM WIZARD */
/*======================================*/
.night .wz-icon-bw li.active ~ li > a .icon-wrap {
  color: #ababab;
}
.night .wz-icon-bw li:not(.active) > a p {
  color: #ababab !important;
}

/* NIFTY PURE CSS SELECT COMPONENT */
/*======================================*/
.night .select:before {
  border-top-color: #858585;
}
.night .pci-hor-dots:before,
.night .pci-ver-dots:before {
  background-color: #ababab;
  box-shadow: 0 0.45em 0 #ababab, 0 0.9em 0 #ababab;
}
/* BREADCRUMB */
/*======================================*/
.night .breadcrumb li,
.night .breadcrumb li a {
  color: #929292;
}
/* POPOVER */
/*======================================*/
.night .popover {
  border: 1px solid #323944;
}
/* WELLS */
/*======================================*/
.night .well {
  background-color: #323944;
  border-color: #2e343e;
}


/* TABS */
/*======================================*/
.night .tab-base .tab-content {
  background-color: #fff;
}
.night .tab-base .tab-content .tab-footer {
  background-color: #3a4250;
  color: #ababab;
  border-color: #363d4a;
}
.nav-tabs li a {
  color: #ababab;
}
.night .nav-tabs > li.active > a,
.night .nav-tabs > li.active > a:hover,
.night .nav-tabs > li.active > a:focus {
  color: #d4d4d4;
}

/* PANELS */
/*======================================*/

.night .panel-footer {
  background-color: #3f4756;
  color: #ababab;
}
/* MAGIC CHECK */
/*======================================*/

body.night .noUi-marker-large,
body.night .noUi-marker {
  background: rgba(255, 255, 255, 0.2);
}

body.night .note-editable.panel-body {
  border: 1px solid rgba(255, 255, 255, 0.03);
}
#content-container:before {
  content: '';
  display: none;
}

.night .page-header {
  color: #d4d4d4;
}
.night #page-head,
.night #page-head h1,
.night #page-head h2,
.night #page-head h3,
.night #page-head h4,
.night #page-head h5,
.night #page-head h6,
.night #page-head .text-main,
.night #page-head .breadcrumb li,
.night #page-head .breadcrumb li a,
.night #page-head .breadcrumb .active {
  color: #d4d4d4;
}
.night #page-head .breadcrumb > li + li:before {
  border-color: #d4d4d4;
}
.night .select select {
  color: #ababab;
}
.night .panel .fixed-fluid [class*="fixed-"] {
  background-color: transparent;
}
.night .fixed-fluid [class*="fixed-"] {
  background-color: #343b47;
  border-radius: 3px;
}
/* BASICS */
/*======================================*/
.night #page-head {
  background: #343b47;
}
body.night,
.night #content-container {
  background-color: #343b47;
  color: #ababab;
}
.night h1,
.night h2,
.night h3,
.night h4,
.night h5,
.night h6,
.night .h1,
.night .h2,
.night .h3,
.night .h4,
.night .h5,
.night .h6,
.night .text-main,
.night a.text-main:hover,
.night a.text-main:focus {
  color: #d4d4d4;
}
.night #page-title,
.night .page-header {
  color: #d4d4d4;
}
.night .page-fixedbar-content {
  background-color: #3d4553;
  color: #ababab;
}
.night .jumbotron {
  padding: 30px;
  background-color: #3d4553;
}
.night .close {
  color: #d4d4d4;
}
.night .close:focus,
.night .close:hover {
  color: #d4d4d4;
}
.night a:not(.btn):not(.alert-link):not(.label):not(.btn-link){
  color: #ababab;
}
.night a:not(.btn):not(.alert-link):not(.label):hover,
.night a:not(.btn):not(.alert-link):not(.label):focus {
  color: #9e9e9e;
}

.night #mainnav-menu ul .active-link a,
.night #mainnav-menu ul a:hover,
.night #mainnav-menu ul ul a:hover,
.night .menu-popover .sub-menu ul .active-link a,
.night .menu-popover .sub-menu ul a:hover,
.night .menu-popover .sub-menu ul ul a:hover {
  color: #fff;
}


.night #mainnav .list-divider {
  border-color: rgba(255, 255, 255, 0.03);
}
/* HORIZONTAL RULE */
/*======================================*/
.night hr {
  border-color: rgba(255, 255, 255, 0.03);
}
/* THUMBNAILS */
/*======================================*/
.night .thumbnail {
  background-color: rgba(255, 255, 255, 0.03);
  border-color: rgba(255, 255, 255, 0.03);
}
.night .thumbnail .caption {
  color: #ababab;
}
/* TABLES */
/*======================================*/
.night #container .table th {
  border-bottom: 1px solid rgba(255, 255, 255, 0.03);
  color: #d4d4d4;
}
.night #container .table td {
  border-top: 1px solid rgba(255, 255, 255, 0.03);
}

.night #container .table.table-borderless td {
  border: none;
}

.night #container .table-bordered,
.night #container .table-bordered td,
.night #container .table-bordered th {
  border-color: rgba(255, 255, 255, 0.03);
}
.night #container .table-striped > tbody > tr:nth-child(2n+1) {
  background-color: rgba(255, 255, 255, 0.025);
}
.night #container .table-hover > tbody > tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
/* FORM CONTROL  */
/*======================================*/
.night .form-control {
  background-color: #414a59;
  border: 1px solid rgba(255, 255, 255, 0.03);
  color: #ababab;
}

.night .form-control[disabled],
.night .form-control[readonly],
.night fieldset[disabled] .form-control {
  background-color: #3a4250;
  color: #858585;
}
.night .form-control-trans {
  border: 0 !important;
  background-color: transparent !important;
}
.night .input-group-addon {
  border: 1px solid rgba(255, 255, 255, 0.03);
  background-color: #414a59;
  color: #ababab;
}

/* LIST GROUP */
/*======================================*/
.night .list-group-striped > li:nth-child(odd),
.night .list-group-striped > a:nth-child(odd):not(.active):not(.disabled) {
  background-color: rgba(255, 255, 255, 0.025);
}
.night .list-group-item {
  border-color: rgba(255, 255, 255, 0.03);
  color: #ababab;
}
.night a.list-group-item,
.night button.list-group-item {
  color: #ababab;
}
.night a.list-group-item:hover,
.night button.list-group-item:hover,
.night a.list-group-item:focus,
.night button.list-group-item:focus {
  background-color: rgba(0, 0, 0, 0.04);
  color: #ababab;
}
.night .list-group-item .list-group-item-heading,
.night a.list-group-item .list-group-item-heading,
.night button.list-group-item .list-group-item-heading {
  color: #d4d4d4;
}
.night .list-group-item.disabled,
.night .list-group-item.disabled:hover,
.night .list-group-item.disabled:focus {
  color: #858585;
}
.night .list-group-item.active .list-group-item-text,
.night .list-group-item.active:hover .list-group-item-text,
.night .list-group-item.active:focus .list-group-item-text {
  color: #fff;
}
/* LABEL */
/*======================================*/
.night .label-default {
  background-color: #566276;
  color: #ababab;
}
.night .label-default[href]:hover,
.night .label-default[href]:focus {
  background-color: #d3dbe4;
}
/* BREADCRUMB */
/*======================================*/
.night .breadcrumb li,
.night .breadcrumb li a {
  color: #d4d4d4;
}

.night .breadcrumb > li + li:before {
  border-color: #d4d4d4;
}
.night .breadcrumb .active {
  color: #d4d4d4;
}
/* MODALS */
/*======================================*/
.night .modal-footer {
  background-color: transparent;
  color: #ababab;
  border-color: rgba(255, 255, 255, 0.03);
}
.night .modal-title {
  font-size: 1.2em;
  color: #d4d4d4;
}
.night .modal-content, .night .modal-iframe {
  background-color: #3d4553 !important;
}
/* TOOLTIP */
/*======================================*/
.night .tooltip-inner {
  background-color: #5f6c82;
}
.night .tooltip.top .tooltip-arrow,
.night .tooltip.top-left .tooltip-arrow,
.night .tooltip.top-right .tooltip-arrow {
  border-top-color: #5f6c82;
}
.night .tooltip.right .tooltip-arrow {
  border-right-color: #5f6c82;
}
.night .tooltip.left .tooltip-arrow {
  border-left-color: #5f6c82;
}
.night .tooltip.bottom .tooltip-arrow,
.night .tooltip.bottom-left .tooltip-arrow,
.night .tooltip.bottom-right .tooltip-arrow {
  border-bottom-color: #5f6c82;
}
/* POPOVER */
/*======================================*/
.night .popover {
  background-color: #465060;
  border-color: rgba(255, 255, 255, 0.03);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}
.night .popover-title {
  color: #d4d4d4;
}

.night .popover.left > .arrow {
  border-left-color: #465060;
}
.night .popover.left > .arrow:after {
  bottom: -9px;
  border-left-color: #465060;
}
.night .popover.right > .arrow {
  border-right-color: #465060;
}
.night .popover.right > .arrow:after {
  border-right-color: #465060;
}
.night .popover.top > .arrow {
  border-top-color: #465060;
}
.night .popover.top > .arrow:after {
  border-top-color: #465060;
}
.night .popover.bottom > .arrow {
  border-bottom-color: #465060;
}
.night .popover.bottom > .arrow:after {
  border-bottom-color: #465060;
}
/* DROPDOWN */
/*======================================*/
.night .dropdown-header {
  color: #d4d4d4;
}
.night .dropdown-menu {
  background-color: #465060;
}
.night .dropdown-menu > li > a {
  color: #ababab;
}
.night .dropdown-menu .divider {
  background-color: rgba(255, 255, 255, 0.03);
}
.night .dropdown-menu.with-arrow:before {
  border-bottom: 7px solid rgba(230, 230, 230, 0.03);
}
.night .dropdown-menu.with-arrow:after {
  border-bottom: 7px solid #465060;
}
/* WELLS */
/*======================================*/
.night .well,
.night pre {
  background-color: #414a59;
  border-color: rgba(255, 255, 255, 0.03);
}
/*======================================*/
/* PAGER */
/*======================================*/
.night .pager li > a,
.night .pager li > span {
  background-color: #434c5c !important;
  border-color: rgba(250, 250, 250, 0.03);
  color: #ababab;
}
.night .pager .disabled > a,
.night .pager .disabled > span {
  color: #6b6b6b !important;
  background-color: #434c5c !important;
  border-color: rgba(250, 250, 250, 0.03) !important;
  box-shadow: none !important;
}
/*======================================*/
/* PAGINATION */
/*======================================*/
.night .pagination > li {
  color: #ababab;
}
.night .pagination > li > a,
.night .pagination > li > span {
  background-color: #414a59;
  border-color: rgba(242, 242, 242, 0.03);
}
.night .pagination > li > span {
  cursor: default;
}
.night .pagination > li > span:hover {
  background-color: #414a59;
  border-color: rgba(255, 255, 255, 0.03);
  color: inherit;
}
.night .pagination .disabled > a,
.night .pagination .disabled > span {
  box-shadow: none !important;
  background-color: #414a59 !important;
  color: #6b6b6b !important;
  border-color: rgba(255, 255, 255, 0.03) !important;
}
/* TABS */
/*======================================*/
.night .tab-base {
  margin-bottom: 15px;
}
.night .tab-base .tab-content {
  background-color: #38404d !important;
}
.night .tab-base .tab-content .tab-footer {
  background-color: #38404d !important;
  color: #ababab;
  border-color: #363d4a;
}
.night .tab-base .nav-tabs {
  border: 0;
}
.night .tab-base .nav-tabs > li:not(.active) > a {
  background-color: #353c48 !important;
}
.night .tab-base .nav-tabs > li:not(.active) > a:hover {
  background-color: #3d4553;
  border-color: #3d4553;
}
.night .tab-base .nav-tabs > .active > a,
.night .tab-base .nav-tabs > .active a:hover,
.night .tab-base .nav-tabs > .active > a:focus {
  background-color: #3d4553 !important;
  border-color: transparent !important;
}
.night .nav-tabs {
  border: none;
}
.night .nav-tabs li a {
  color: #ababab !important;
}
.night .nav-tabs > li.active > a,
.night .nav-tabs > li.active > a:hover,
.night .nav-tabs > li.active > a:focus {
  background-color: #38404d !important;
  color: #d4d4d4 !important;
  border-color: transparent !important;
}

/* BUTTONS */
/*======================================*/

.night .btn-default-mix,
.night .btn-default.disabled,
.night .btn-default:disabled,
.night .btn-default:active:not([class*="btn-active-"]),
.night .btn-default.active:not([class*="btn-active-"]),
.night .btn-default:hover:not([class*="btn-hover-"]):not([class*="btn-active-"]),
.night .btn-default[class^="btn-active-"]:hover:not(:active):not(.active):not(.dropdown-toggle),
.night .btn-default[class*=" btn-active-"]:hover:not(:active):not(.active):not(.dropdown-toggle),
.night .btn-default:not([class*="btn-active-"]) + .dropdown-menu > li > a:hover,
.night .btn-default:not([class*="btn-active-"]) + .dropdown-menu > li > a:focus,
.night .btn-default:not([class*="btn-active-"]) + .dropdown-menu > .active > a,
.night .btn-default + .dropdown-menu:not(.head-list) > li > a.active,
.night .open > .dropdown-toggle.btn-default,
.night .dropdown > .dropdown-toggle.btn-default:hover,
.night .dropdown.open > .dropdown-toggle.btn-default {
  background-color: #38404d !important;
  border-color: #38404d !important;
  color: #ababab !important;
}

.night .dropdown-menu>li>a:focus {
  background-color: #20325f;
}
.night .btn-default i {
  color: inherit;
}
.night .btn-default,
.night .btn-default:focus {
  background-color: #414a59;
  border-color: rgba(255, 255, 255, 0.03) !important;
  color: #ababab;
}
/* PANELS */
/*======================================*/
.night .panel,
.night .bg-img ~ .cls-content .panel {
  background-color: #3d4553;
}
.night .panel-heading {
  color: #d4d4d4;
}
.night .panel-default.panel-colorful {
  background-color: #3d4553;
  color: #d4d4d4;
}
.night .panel-default .panel-heading {
  background-color: #3d4553;
  border-color: #3d4553;
}
.night .panel-footer {
  background-color: #3f4756;
  color: #ababab;
  border-color: rgba(255, 255, 255, 0.03);
}


/* BLOGS */
/*======================================*/
.night .blog-details .blog-header small {
  background-color: #3d4553;
}
.night .blog .blog-footer {
  border-color: rgba(255, 255, 255, 0.03);
}
/* EMAIL */
/*======================================*/
.night .mail-list .mail-list-unread a {
  color: #d4d4d4;
}
.night .mail-nav-unread {
  color: #d4d4d4 !important;
}
.night .mail-message {
  border-color: rgba(255, 255, 255, 0.03);
}
.night .mail-message strong,
.night .mail-message b {
  color: #d4d4d4;
}
/* TAGS */
/*======================================*/
.night .tag:not(.label) {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.05);
  color: inherit;
}
/* BORDER */
/*======================================*/

.night .bord-all {
  border: 1px solid rgba(255, 255, 255, 0.03);
}
.night .bord-top {
  border-top: 1px solid rgba(255, 255, 255, 0.03);
}
.night .bord-btm {
  border-bottom: 1px solid rgba(255, 255, 255, 0.03);
}
.night .bord-lft {
  border-left: 1px solid rgba(255, 255, 255, 0.03);
}
.night .bord-rgt {
  border-right: 1px solid rgba(255, 255, 255, 0.03);
}
.night .bord-ver {
  border-top: 1px solid rgba(255, 255, 255, 0.03);
  border-bottom: 1px solid rgba(255, 255, 255, 0.03);
}
.night .bord-hor {
  border-right: 1px solid rgba(255, 255, 255, 0.03);
  border-left: 1px solid rgba(255, 255, 255, 0.03);
}
/* FIXED FLUID GRID SYSTEM */
/*======================================*/
@media (min-width: 768px) {
  .night .fixed-fluid > .fixed-left-border {
    border-left: 1px solid rgba(255, 255, 255, 0.03);
  }
  .night .fixed-fluid > .fixed-right-border {
    border-right: 1px solid rgba(255, 255, 255, 0.03);
  }
}
/* TEXTS */
/*======================================*/
.night .text-light,
.night a.text-light:hover,
.night a.text-light:focus {
  color: #fff;
}
.night .text-muted,
.night a.text-muted:hover,
.night a.text-muted:focus {
  color: #808080;
}

.night legend {
  color: #ababab;
}


/* MAGIC CHECK */
/*======================================*/
.night .magic-radio + label:before,
.night .magic-checkbox + label:before {
  border-color: rgba(255, 255, 255, 0.15);
}
.night .magic-radio:checked + label:before {
  border-color: rgba(255, 255, 255, 0.7);
}
/* NIFTY PURE CSS SELECT COMPONENT */
/*======================================*/
.night .select select {
  background-color: #414a59;
  border-color: rgba(255, 255, 255, 0.03);
}
.night .demo-icon-list i,
.night .demo-icon-list .demo-icon:hover,
.night .demo-flag-name {
  color: #d4d4d4;
}
.night .bootstrap-tagsinput {
  background-color: #414a59;
  border-color: rgba(255, 255, 255, 0.03);
}
.night .flotTip,
.night .flotTip .text-main {
  color: #414a59 !important;
}
/* NIFTY OVERLAY */
/*======================================*/
.night .panel-overlay {
  background-color: #3d4553;
  opacity: 0.75;
}
body.night .dd-bg,
body.night .dd-content {
  background-color: #414a59;
  color: inherit;
}
body.night .dd-bg:hover {
  background-color: #38404d;
}
body.night .dd-placeholder,
body.night .dd-empty {
  border: 1px dashed #a0a4a8;
  background-color: #4a5364;
}
body.night .jstree-default .jstree-hovered {
  background-color: rgba(255, 255, 255, 0.07);
}

body.night #container .fc-unthemed th,
body.night #container .fc-unthemed td,
body.night #container .fc-unthemed hr,
body.night #container .fc-unthemed thead,
body.night #container .fc-unthemed tbody,
body.night #container .fc-unthemed .fc-row,
body.night #container .fc-unthemed .fc-popover {
  border-color: rgba(255, 255, 255, 0.03);
}
body.night #container .fc-unthemed .fc-popover .fc-header,
body.night #container .fc-unthemed hr {
  background-color: rgba(255, 255, 255, 0.03);
}
body.night #container .fc-button,
body.night #container .fc button {
  background-color: #414a59;
  border-color: rgba(255, 255, 255, 0.03) !important;
  color: #ababab;
}
body.night #container .fc-button:hover,
body.night #container .fc button:hover {
  background-color: #38404d;
  border-color: #38404d;
  color: #ababab;
}
body.night #container .fc-event.fc-list {
  background-color: #475161;
}
body.night .select2-dropdown {
  background-color: #414a59;
  border: 1px solid rgba(255, 255, 255, 0.03);
}
body.night .select2-close-mask {
  background-color: #414a59;
}
body.night .select2-container--default .select2-selection--single {
  background-color: #414a59;
  border-color: rgba(255, 255, 255, 0.03) !important;
}
body.night .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #d4d4d4 !important;
}
body.night .select2-results__group,
body.night .select2-container--default .select2-results__option .select2-results__option,
body.night .select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #d4d4d4;
}
body.night .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #d4d4d4 transparent transparent transparent;
}
body.night .select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #414a59;
  cursor: default;
}
body.night .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #d4d4d4 transparent;
}
body.night .select2-container--default .select2-selection--multiple {
  background-color: #414a59;
  border: 1px solid rgba(255, 255, 255, 0.03);
}
body.night .select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #d4d4d4;
}
body.night .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #fff;
}
body.night .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #d4d4d4;
}
body.night .select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #414a59;
}
body.night .select2-container--default .select2-search--dropdown .select2-search__field {
  background-color: #475161;
  border: 1px solid rgba(255, 255, 255, 0.03);
}
body.night .select2-container--default .select2-results__option[aria-disabled=true] {
  color: #d4d4d4;
}
body.night .select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #414a59;
}

body.night .chosen-container-single .chosen-single,
body.night .chosen-container .chosen-drop,
body.night .chosen-container .chosen-results li.no-results {
  background-color: #414a59;
  color: #d4d4d4;
}
body.night .chosen-container-single .chosen-single div b:before,
body.night .chosen-container .chosen-results {
  color: #d4d4d4;
}
body.night .chosen-container-active.chosen-with-drop .chosen-single {
  background-color: #414a59;
  border-color: rgba(255, 255, 255, 0.03);
  box-shadow: none;
  color: #d4d4d4;
}
body.night .chosen-container-active .chosen-choices,
body.night .chosen-container-single .chosen-search input[type="text"] {
  background-color: #475161;
  border-color: rgba(255, 255, 255, 0.03);
}
body.night .chosen-container-single .chosen-single,
body.night .chosen-container .chosen-drop {
  border-color: rgba(255, 255, 255, 0.03);
}
body.night .chosen-container-multi .chosen-choices {
  background-color: #414a59;
  border-color: rgba(255, 255, 255, 0.03);
  color: #d4d4d4;
}
body.night .bootstrap-select > .btn.btn-default.dropdown-toggle,
body.night .bootstrap-select > .btn.btn-default.dropdown-toggle:hover {
  background-color: #414a59;
  color: #d4d4d4;
  border-color: rgba(255, 255, 255, 0.03) !important;
}
body.night .dropdown-menu li:not(.active) a:not(:hover),
body.night .dropdown-menu li:not(.active) a:not(:hover) i,
body.night .bootstrap-select > .btn.btn-default .caret {
  color: #d4d4d4;
}
body.night .bootstrap-select.btn-group .no-results {
  background-color: transparent;
  color: #d4d4d4;
}
body.night .bootstrap-select .form-control {
  background-color: #414a59;
  border: 1px solid rgba(255, 255, 255, 0.03);
  color: #ababab;
}
body.night .bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}
body.night .bootstrap-timepicker-widget table td a {
  color: #d4d4d4;
}
body.night .datepicker table thead {
  color: #d4d4d4;
}
body.night .noUi-marker-large,
body.night .noUi-marker {
  background: rgba(255, 255, 255, 0.2);
}
body.night .switchery {
  background-color: rgba(255, 255, 255, 0.2) !important;
  border-color: rgba(0, 0, 0, 0.07) !important;
}
body.night .note-editable.panel-body {
  border: 1px solid rgba(255, 255, 255, 0.03);
}
body.night .note-editor.note-frame .note-editing-area .note-editable {
  background-color: #414a59;
}
body.night .note-editor.note-frame .note-statusbar {
  background-color: #38404d;
}
body.night .note-editor.note-frame .note-editing-area .note-editable {
  color: #d4d4d4;
}
body.night .md-editor,
body.night .md-editor active {
  border-color: #414a59;
}
body.night .md-editor > .md-header,
body.night .md-editor .md-footer {
  background-color: #38404d;
}
body.night .md-editor > textarea {
  background-color: #414a59;
  border-color: #414a59;
}
body.night .md-editor > .md-preview,
body.night .md-editor > textarea:focus {
  background-color: #475161;
  border-color: #414a59;
}
body.night .md-editor.md-fullscreen-mode {
  background-color: #38404d !important;
}
body.night .md-editor.md-fullscreen-mode .md-input,
body.night .md-editor.md-fullscreen-mode .md-preview,
body.night .md-editor.md-fullscreen-mode .md-input:hover,
body.night .md-editor.md-fullscreen-mode .md-input:focus {
  background-color: #475161 !important;
  color: #ababab;
}

.night .daterangepicker .calendar-date {
  background: inherit !important;
}

.night .daterangepicker.opensright:after{
  border-bottom-color: #465060;
}

.night .bg-light {
  color: #4d627b !important;
}

.night th.bg-light,
.night td.bg-light {
  background-color: #3D4553;
  color: #d4d4d4 !important;
}

.night .multiple > .form-row {
  background-color: #414a59;
}

.night a.delete-row {
  background-color: #414a59;
}

.night select {
  background-color: #414a59;
  border: 1px solid rgba(255, 255, 255, .03);
  color: #ababab;
}

.night .active > span.page-link {
  background-color: #1976d2;
  border-color: #1976d2;
  color: #fff;
}

.night .active > span.page-link:hover {
  background-color: #1976d2;
  border-color: #1976d2;
  color: #fff;
}

.night .btn-default:not([class*=btn-active-])+.dropdown-menu>li>a:focus,
.night .btn-default:not([class*=btn-active-])+.dropdown-menu>li>a:hover{
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
  color: #fff !important;
}

.night .dropdown-menu.extension-dropdown>li>a.bg-night {
  color:#9e9e9e !important;
  background-color: #20325f !important;
}
